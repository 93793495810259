import { postAuth } from "../../globalUtils";

const post_url = "/post/media";

export const getPostsFromApi = async ({
  column,
  search,
  page = 1,
  limit = 10,
  date,
  isLoadBeforeDate,
}) => {
  try {
  
    const url =
      `${post_url}?page=${page}&limit=${limit}` +
      (date ? `&date=${date}` : "") +
      `&isLoadBeforeDate=${isLoadBeforeDate}`;

    const formattedData = {
      channels: column.channels.map((channel) => channel.id),
      filters: {
        get_image: column.get_image,
        get_video: column.get_video,
        get_preview: column.get_preview,
        get_text_only: column.get_text_only,
      },
      search: search,
    };

    const response = await postAuth(url, formattedData);

    if (!response.ok) {
      throw new Error("Failed to fetch posts from API");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in getPostsFromApi:", error);
    throw error;
  }
};

export const export2csv = (posts) => {
  const header = [
    "channel_name",
    "channel_username",
    "date",
    "time",
    "text",
    "post_url",
  ];

  const csv = posts.map((row) =>
    header
      .map((fieldName) => {
        if (fieldName === "date" || fieldName === "time") {
          if (row.timestamp) {
            const dateObj = new Date(row.timestamp);
            const dateString = dateObj.toLocaleDateString("en-US");
            const timeString = dateObj.toLocaleTimeString("en-US", {
              timeStyle: "short",
            });

            return fieldName === "date" ? dateString : timeString;
          } else {
            return "";
          }
        } else {
          let actualFieldName = fieldName;
          if (fieldName === "channel_name") actualFieldName = "chat_title";
          if (fieldName === "channel_username")
            actualFieldName = "sender_username";

          if (fieldName === "text") {
            // Replace newline characters with spaces
            return row[actualFieldName]?.replace(/\n/g, " ") ?? "";
          }

          // Return the value directly
          return row[actualFieldName] ?? "";
        }
      })
      .join("+")
  );

  csv.unshift(header.join("+"));
  const csvString = csv.join("\r\n");

  const blob = new Blob(["\uFEFF" + csvString], {
    type: "text/csv;charset=utf-8;",
  });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = "bookmarked_posts.csv";
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  }, 100);
};
