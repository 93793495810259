import React from "react";
import Themes from "./themes";
import { ContentRow } from "./ContentRow";
import Button from "@mui/material/Button";
import { handleLogout } from "../../authentication/Logout/Logout_utils";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  },
});

const Settings = () => {
  return (
    <ThemeProvider theme={theme}>
      <Themes />
      <ContentRow title={"Logout"}>
        <Button
          variant="contained"
          color="error"
          sx={{
            height: "39px",
            width: "68px",
            fontWeight: "inherit",
            textTransform: "none",
            fontSize: "14px",
            borderRadius: "4px",
          }}
          onClick={handleLogout}
        >
          Logout
        </Button>
      </ContentRow>
    </ThemeProvider>
  );
};

export default Settings;
