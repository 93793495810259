import React, { useState, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { defaultColumn } from "./BookmarkTab";
import { useBookmarks } from "../bookmarks/BookmarkContext";
import CopyPost from "../CopyPost";
import SharePopup from "../SharePopup";
import { useTheme } from "../../themes/ThemeContext";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";

// This container will serve as the parent container for the whole page
const PageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  height: 95vh; /* Full viewport height for scrollable content */
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
  }
`;

const BookmarksContainer = styled.div`
  width: 400px;
  height: 95vh;
  flex-shrink: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.popup_background};
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const WidgetContainer = styled.div`
  display: flex;
  width: calc(100% - 400px);
  flex-direction: column;
  background-color: ${({ theme }) => theme.popup_background};
  padding: 20px;
  padding-top: 0px;
  padding-right: 0px;
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  height: 95vh; /* Ensures it fills the container */

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ThemedInput = styled.input`
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border-color: ${({ theme }) => theme.border_color};
  width: 96%;
  margin: 0 auto;
`;

const StyledQuillContainer = styled.div`
  width: 100%;
`;

const CenteredHeading = styled.h2`
  text-align: center;
  padding: 0;
`;

const BackLinkContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.link_color}; /* Use theme colors */
  font-size: 16px;
  font-weight: bold;
  transition: color 0.3s ease;

  &:hover {
    color: blue; /* Hover effect */
  }
`;

const ShareLinkContainer = styled.div`
  display: flex;
  cursor: pointer;
  color: ${({ theme }) => theme.text}; /* Use theme color */
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;

  &:hover {
    color: blue; /* Hover effect */
  }

  .material-icons {
    margin-left: 5px; /* Space between text and icon */
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; // Adjust as needed
  padding: 10px 10px 0 10px;
`;

function BookmarkEditPage() {
  const { bookmarkedPosts } = useBookmarks();
  const [searchTerm, setSearchTerm] = useState("");
  const [media, setMedia] = useState(null);
  const editorRef = useRef(null);
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);

  const toggleSharePopup = () => {
    setIsSharePopupOpen(!isSharePopupOpen);
  };

  const [textInput, setTextInput] = useState(() => {
    const savedText = localStorage.getItem("textInput");
    return savedText !== null ? savedText : "";
  });

  useEffect(() => {
    localStorage.setItem("textInput", textInput);
  }, [textInput]);

  const handleEditorChange = (content, editor) => {
    setTextInput(content);
  };

  const handleTextInput = (textInput) => {
    // Create a temporary div to use for stripping HTML and decoding entities
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = textInput;

    // Extract the src attribute of the first img tag if it exists
    const imgTag = tempDiv.querySelector("img");
    const mediaUrl = imgTag ? imgTag.src : "";

    // Extract text content, which strips tags and decodes entities
    let cleanedInput = tempDiv.textContent || tempDiv.innerText || "";

    // Remove invisible characters (e.g., zero-width spaces) if necessary
    cleanedInput = cleanedInput.replace(/[\u200B-\u200D\uFEFF]/g, "");

    // Trim the result to remove leading and trailing spaces
    cleanedInput = cleanedInput.trim();

    // Combine the truncated media URL and the cleaned text content
    const result = mediaUrl ? `${mediaUrl}\n${cleanedInput}` : cleanedInput;

    return result;
  };

  useEffect(() => {
    if (editorRef.current && media) {
      // Insert the media into the editor
      const imgHtml = `<img src="${media}" width="600" height="400" alt="Media" />`;

      // Optionally, clear the previous content or perform additional logic

      editorRef.current.insertContent(imgHtml);

      // Reset media after insertion if needed
      setMedia("");
    }
  }, [media]);

  const filteredPosts = useMemo(() => {
    return bookmarkedPosts.filter((post) =>
      post.text.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [bookmarkedPosts, searchTerm]);

  return (
    <PageContainer>
      <BookmarksContainer>
        <CenteredHeading>Bookmarked Posts</CenteredHeading>
        <div className="draggable-input-icon-container">
          <ThemedInput
            type="text"
            className="ColumnNameInput TextArea"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <span className="material-icons search-icon">search</span>
        </div>
        {filteredPosts.length > 0 ? (
          filteredPosts.map((post) => (
            <div key={post.post_id}>
              <CopyPost
                post={post}
                column={defaultColumn}
                is_a_bookmark={true}
                setTextInput={setTextInput}
                setMedia={setMedia}
              />
            </div>
          ))
        ) : (
          <p>No bookmarks yet.</p>
        )}
      </BookmarksContainer>

      <WidgetContainer>
        <FlexContainer>
          <ShareLinkContainer
            onClick={() => {
              toggleSharePopup();
            }}
          >
            Share
            <span className="material-icons">share</span>
          </ShareLinkContainer>
          <SharePopup
            isOpen={isSharePopupOpen}
            togglePopup={toggleSharePopup}
            text={handleTextInput(textInput)}
          />
          <CenteredHeading>Text Editor</CenteredHeading>
          <BackLinkContainer
            onClick={() => {
              navigate("/");
            }}
          >
            Go To Home
            <span className="material-icons">keyboard_arrow_right</span>
          </BackLinkContainer>
        </FlexContainer>
        <StyledQuillContainer>
          <Editor
            apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
            value={textInput}
            init={{
              height: 800,
              plugins:
                "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
              toolbar:
                "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
            }}
            onEditorChange={handleEditorChange}
            onInit={(evt, editor) => (editorRef.current = editor)}
          />
        </StyledQuillContainer>
      </WidgetContainer>
    </PageContainer>
  );
}

export default BookmarkEditPage;
