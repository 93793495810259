import AreaTable from "../components/dashboard/areaTable/BusinessUsers/AreaTable";

const BusinessUsers = () => {
  return (
    <div className="content-area">
      <AreaTable />
    </div>
  );
};

export default BusinessUsers;
