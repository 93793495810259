import Swal from "sweetalert2";
import { postAuth } from "../../../globalUtils";

export const handleLogout = async () => {
  // Show confirmation dialog
  Swal.fire({
    title: "Are you sure?",
    text: "You will be logged out of your account.",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, log me out",
  }).then((result) => {
    if (result.isConfirmed) {
      performLogout();
    }
  });
};

const performLogout = async () => {
  try {
    const response = await postAuth("/auth/logout");
    const data = await response.json();
    if (response.ok && data.status === "success") {
      Swal.fire({
        icon: "success",
        title: "Logout Successful",
      });
      window.location.href = "/signin"; // Redirect to the signin page
    } else {
      console.error("Logout failed:", data.message);
      Swal.fire({
        icon: "error",
        title: "Logout Failed",
        text: "Please try again",
      });
    }
  } catch (error) {
    console.error("Logout failed:", error.message);
    Swal.fire({
      icon: "error",
      title: "Logout Failed",
      text: "Please try again later",
    });
  }
};
