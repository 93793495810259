import React, { useState, useEffect, useMemo } from "react";
import Post from "../../post/Post";
import styled from "styled-components";
import Spinner from "../../../Spinner";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import {useTheme} from "../../themes/ThemeContext";

countries.registerLocale(enLocale);

const defaultColumn = {
  get_video: true,
  get_image: true,
  get_preview: true,
  get_text_only: true,
};

export const ToggleButton = styled.button`
  position: fixed;
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  right: ${(props) => (props.$show ? "370px" : "-8px")};
  top: ${(props) =>
    props.$top}px; /* Use $ prefix to make it a transient prop */
  z-index: 1000;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  display: flex;
  justify-content: end;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  width: 60px;
  transition: right 0.3s ease; /* Add animation */

  &:hover {
    background-color: ${({ theme }) => theme.cancel_hover};
  }

  span {
    margin-left: 8px;
    color: #333;
    font-size: 16px;
  }
`;

const NewsList = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  width: 340px;
  height: calc(100vh - 40px);
  padding: 20px;
  background-color: ${({ theme }) => theme.column_background};
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 10000000000;
  transform: translateX(
    ${(props) => (props.$show ? "0" : "100%")}
  ); /* Use $ prefix to make it a transient prop */
  transition: transform 0.3s ease;

  &::-webkit-scrollbar {
    width: 12px; /* Adjusted width */
  }
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.chip_inactive};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.background};
    border-radius: 10px;
    border: 3px solid ${({ theme }) => theme.chip_inactive};
  }
`;

const LoadButton = styled.button`
  font-size: 14px;
  font-weight: bold;
  margin-top: 2px;
  padding: 10px;
  background-color: ${({ theme }) => theme.chip_inactive};
  color: ${({ theme }) => theme.text};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  &:hover {
    opacity: 0.87;
  }
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const LargeSpinner = styled(Spinner)`
  width: 250px;
  height: 250px;
`;

const NewsHeader = styled.h2`
  text-align: center;
`;

const NoNewsMessage = styled.h3`
  margin-left: 10px;
`;

const Icon = styled.span`
  font-size: 30px !important;
`;

export const NewsMapSidebar = ({
  news,
  country,
  openSidebar,
  loading,
  setPage,
  handleOpenPostPopup,
}) => {
  const [showBookmark, setShowBookmark] = useState(false);
  const [top, setTop] = useState(70);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const { theme } = useTheme();

  const countryName = countries.getName(country, "en");

  const toggleBookmarks = (e) => {
    setShowBookmark((prev) => !prev);
  };

  useEffect(() => {
    if (openSidebar) {
      setShowBookmark(true);
    }
  }, [openSidebar]);

  // These functions are for dragging the toggle button
  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setStartY(e.clientY - top);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const newTop = e.clientY - startY;
      const windowHeight = window.innerHeight;
      const boundedTop = Math.max(0, Math.min(newTop, windowHeight - 50));
      setTop(boundedTop);
      e.preventDefault();
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, startY]);

  // Filter out posts with no text
  const filteredPosts = useMemo(() => {
    return news.filter((post) => post?.text?.toLowerCase() ?? false);
  }, [news]);

  // Set the font size of the news text to 16px
  useEffect(() => {
    const newsListTextElements = document.querySelectorAll(".post-text");

    newsListTextElements.forEach((element) => {
      element.style.fontSize = "16px";
    });
  }, [news]);

  return (
    <div>
      <ToggleButton
        className="toggle-button"
        $top={top}
        $show={showBookmark}
        onClick={toggleBookmarks}
        onMouseDown={handleMouseDown}
        $theme={theme}
      >
        <Icon className="material-symbols-outlined">news</Icon>
      </ToggleButton>
      <NewsList className="sidebar-element" $show={showBookmark}>
        <NewsHeader>
          {country ? `News in ${countryName}` : "Latest News"}
        </NewsHeader>
        {filteredPosts.length > 0 ? (
          <>
            {filteredPosts.map((post, index) => (
              <div key={`${post.post_id}-${index}`}>
                <Post
                  post={post}
                  column={defaultColumn}
                  onOpenPopup={handleOpenPostPopup}
                />
              </div>
            ))}

            <LoadButton
              onClick={() => {
                setPage((prev) => prev + 1);
              }}
            >
              Load More
            </LoadButton>
          </>
        ) : loading ? (
          <CenteredContainer>
            <LargeSpinner />
          </CenteredContainer>
        ) : (
          <NoNewsMessage>No News yet</NoNewsMessage>
        )}
      </NewsList>
    </div>
  );
};

export default NewsMapSidebar;
