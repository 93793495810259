import Swal from "sweetalert2";
import { PhoneNumberUtil } from "google-libphonenumber";
import { post, postAuth } from "../../../globalUtils";

export const passwordRegex =
  /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])(?=.*[a-z]).{8,}$/;

export const validateEmail = (email) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email) ? "" : "Enter a valid email address";
};

export const isPhoneValid = (phone) => {
  const trimmedPhone = phone.trim();
  if (
    !trimmedPhone ||
    trimmedPhone === "+1" ||
    trimmedPhone.match(/^\+\d{1,3}$/)
  ) {
    return null; // No phone number provided
  }
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    const number = phoneUtil.parseAndKeepRawInput(trimmedPhone);
    return phoneUtil.isValidNumber(number) ? "" : "Invalid Phone Number";
  } catch (error) {
    return "Invalid Phone Number";
  }
};

// Password validation function
export const validatePassword = (password) => {
  return passwordRegex.test(password)
    ? ""
    : "Password must have at least 8 characters, 1 capital letter, 1 special character, and 1 number";
};

// Compare two passwords
export const comparePasswords = (password, confirmPassword) => {
  if (password !== confirmPassword) {
    return "Passwords do not match";
  } else if (!passwordRegex.test(password)) {
    return "Password must have at least 8 characters, 1 capital letter, 1 special character, and 1 number";
  } else {
    return "";
  }
};

export const showErrorAlert = (title, text) => {
  Swal.fire({
    icon: "error",
    title: title,
    text: text,
  });
};

export const registerUser = async (formData) => {
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    organization,
    password,
    confirmPassword,
  } = formData;

  // Validate inputs
  const emailError = validateEmail(email);
  const phoneError = phoneNumber ? isPhoneValid(phoneNumber) : "";
  const passwordError = validatePassword(password);
  const confirmPasswordError = comparePasswords(password, confirmPassword);

  const errors = [
    emailError,
    passwordError,
    confirmPasswordError,
    phoneError,
  ].filter((e) => e);

  if (errors.length > 0) {
    return {
      success: false,
      message: errors || "Failed to verify email",
    };
  }

  const requestBody = {
    firstName,
    lastName,
    email,
    phoneNumber:
      phoneError === null || phoneError === "Invalid Phone Number"
        ? ""
        : phoneNumber,

    organization,
    password,
  };

  try {
    const url = `/auth/register`;

    const response = await post(url, requestBody);

    const result = await response.json();

    if (response.ok) {
      Swal.fire({
        icon: "success",
        title: "Registration Successful",
        text: "Welcome to our platform!",
      });

      return {
        success: true,
        data: result.message,
      };
    } else {
      console.error("Registration Error:", result.message);
      return {
        success: false,
        message: result.message || "Unknown error occurred",
      };
    }
  } catch (error) {
    console.error("Network Error:", error);

    return {
      success: false,
      message: "Please check your connection and try again.",
    };
  }
};

export const getOrgs = async () => {
  try {
    const url = `/organizations/names`;

    const response = await postAuth(url);

    const result = await response.json();

    if (response.ok) {
      return {
        success: true,
        data: result.data,
      };
    } else {
      console.error("Failed to get organizations:", result.message);
      return {
        success: false,
        message: result.message || "Unknown error occurred",
      };
    }
  } catch (error) {
    console.error("Network Error:", error);

    return {
      success: false,
      message: "Please check your connection and try again.",
    };
  }
};
