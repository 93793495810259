import React, { useState } from "react";
import styled from "styled-components";
import { requestChannel } from "./ContactApi";
import Swal from "sweetalert2";
import { useTheme } from "../../../themes/ThemeContext";

// Styled components
export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
`;

export const Modal = styled.div`
  background-color: ${({ theme }) => (theme === "dark" ? "#333" : "white")};
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
`;

export const CloseButton = styled.button`
  float: right;
  border: none;
  background: none;
  font-size: 30px;
  cursor: pointer;
  margin-top: 10px;
  color: ${({ theme }) => (theme === "dark" ? "white" : "black")};
`;

export const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: ${({ theme }) => (theme === "dark" ? "#555" : "#007bff")};
  color: white;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 8px 40px 8px 8px;
  border: ${({ theme }) =>
    theme === "dark" ? "1px solid #555" : "1px solid #ccc"};
  border-radius: 5px;
  background-color: ${({ theme }) => (theme === "dark" ? "#222" : "white")};
  color: ${({ theme }) => (theme === "dark" ? "#ddd" : "#333")};
`;

export const RemoveButton = styled.button`
  display: flex;
  margin-left: 10px;
  background-color: ${({ theme }) => (theme === "dark" ? "#666" : "#dc3545")};
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => (theme === "dark" ? "#555" : "#c82333")};
  }
`;

const ChannelRequestPopup = ({ isOpen, onClose }) => {
  const [inputs, setInputs] = useState([""]);
  const { theme } = useTheme();

  const handleInputChange = (index, event) => {
    const newInputs = [...inputs];
    newInputs[index] = event.target.value;
    setInputs(newInputs);
  };

  const handleAddInput = () => {
    setInputs([...inputs, ""]);
  };

  const handleRemoveInput = (index) => {
    if (inputs.length > 1) {
      const newInputs = inputs.filter((_, idx) => idx !== index);
      setInputs(newInputs);
    }
  };

  const handleSubmit = async () => {
    const channels = inputs.filter((input) => input.trim() !== "");
    const result = await requestChannel(channels);
    if (result.success) {
      Swal.fire({
        icon: "success",
        title: "Request Sent",
        text: result.message,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Request Failed",
      });
    }
    setInputs([""]); // Reset inputs
    onClose();
  };

  if (!isOpen) return null;

  return (
    <Overlay>
      <Modal theme={theme}>
        <CloseButton theme={theme} onClick={onClose}>
          &times;
        </CloseButton>
        <h3>Request Extra Channels</h3>
        {inputs.map((input, index) => (
          <InputContainer key={index}>
            <Input
              type="text"
              placeholder="Enter channel username..."
              value={input}
              onChange={(e) => handleInputChange(index, e)}
              theme={theme}
            />
            {index > 0 && (
              <RemoveButton onClick={() => handleRemoveInput(index)}>
                <span className="material-icons">cancel</span>
              </RemoveButton>
            )}
          </InputContainer>
        ))}
        <SubmitButton onClick={handleAddInput} theme={theme}>
          Add More
        </SubmitButton>
        <SubmitButton onClick={handleSubmit} theme={theme}>
          Submit All
        </SubmitButton>
      </Modal>
    </Overlay>
  );
};

export default ChannelRequestPopup;
