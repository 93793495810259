import React from "react";
import MainMapComponent from "./map_components/MainMapComponent";

const Map = () => {
  return (
    <div>
      <MainMapComponent />
    </div>
  );
};
export default Map;
