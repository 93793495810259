import React, { useState, useEffect } from "react";

const NewsMonitoringProgressChart = () => {
  const [serviceData, setServiceData] = useState(null);

  useEffect(() => {
    // Mock data fetching
    const fetchData = async () => {
      // Simulate an API call
      const mockData = {
        politicsCount: 40,
        technologyCount: 25,
        sportsCount: 15,
        entertainmentCount: 10,
        healthCount: 10,
      };
      setServiceData({ data: mockData });
    };

    fetchData();
  }, []);

  const data = [
    {
      id: 1,
      name: "Politics",
      percentValues: serviceData
        ? `${serviceData.data.politicsCount || "N/A"}`
        : "Loading...",
    },
    {
      id: 2,
      name: "Technology",
      percentValues: serviceData
        ? `${serviceData.data.technologyCount || "N/A"}`
        : "Loading...",
    },
  ];

  return (
    <div className="progress-bar">
      <div className="progress-bar-info">
        <h4 className="progress-bar-title">
          Total Number of Posts Per Category
        </h4>
      </div>
      <div className="progress-bar-list">
        {data?.map((progressbar) => {
          return (
            <div className="progress-bar-item" key={progressbar.id}>
              <div className="bar-item-info">
                <p className="bar-item-info-name">{progressbar.name}</p>
                <p className="bar-item-info-value">
                  {progressbar.percentValues}%
                </p>
              </div>
              <div className="bar-item-full">
                <div
                  className="bar-item-filled"
                  style={{
                    width: `${progressbar.percentValues}%`,
                    backgroundColor:
                      progressbar.id % 2 === 0 ? "#4caf50" : "#f44336",
                  }}
                ></div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NewsMonitoringProgressChart;
