import React from "react";
import styled from "styled-components";
import {
  TwitterShareButton,
  WhatsappShareButton,
  TwitterIcon,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";
import { SiMicrosoftword } from "react-icons/si";

// Modal styles
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const ModalContent = styled.div`
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  height: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const ShareLinkContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.text};
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;

  &:hover {
    color: blue;
  }

  .material-icons {
    margin-left: 5px;
  }
`;

const ShareButtons = ({ text }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <p>Share Using</p>
      <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        <TwitterShareButton url={" "} title={text} hashtag={`#teledeck`}>
          <TwitterIcon size={40} round />
        </TwitterShareButton>
        <WhatsappShareButton url={" "} title={text} separator="">
          <WhatsappIcon size={40} round />
        </WhatsappShareButton>
        <TelegramShareButton url={" "} title={text}>
          <TelegramIcon size={40} round />
        </TelegramShareButton>
      </div>
    </div>
  );
};

const DownloadButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Space between icon and text */
  padding: 10px 20px;
  border: 2px solid #2b579a;
  background-color: white;
  cursor: pointer;
  color: #2b579a;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #2b579a;
    color: white;
  }

  svg {
    font-size: 20px; /* Size of the icon */
  }
`;

const SharePopup = ({ isOpen, togglePopup, text }) => {
  const exportToWord = () => {
    const header = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Word Document</title></head><body>`;
    const footer = `</body></html>`;
    const formattedTextInput = text.replace(/\n/g, "<br>");
    const sourceHTML = header + formattedTextInput + footer;

    const source =
      "data:application/vnd.ms-word;charset=utf-8," +
      encodeURIComponent(sourceHTML);
    const fileDownload = document.createElement("a");
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = "Teledeck.doc";
    fileDownload.click();
    document.body.removeChild(fileDownload);
  };

  return (
    <>
      {isOpen && (
        <ModalBackground onClick={togglePopup}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={togglePopup}>×</CloseButton>
            <ShareButtons text={text} />
            <p
              style={{
                margin: "0px",
                padding: "0px",
              }}
            >
              Or
            </p>
            <DownloadButton onClick={exportToWord}>
              <SiMicrosoftword />
              Download as Word
            </DownloadButton>
          </ModalContent>
        </ModalBackground>
      )}
    </>
  );
};

export default SharePopup;
