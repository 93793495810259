import React, { useState } from "react";
import { Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import styled from "styled-components";

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin-bottom: 20px;
  width: 100%;
`;

const SearchInput = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex: 1;
  margin-right: 20px;
  outline: none;
  max-width: 400px;

  &:focus {
    border-color: #007bff;
  }
`;

const SearchBar = ({ setSearchTerm, handleDownloadCSV }) => {
  const [localSearchTerm, setLocalSearchTerm] = useState("");

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setSearchTerm(localSearchTerm);
    }
  };

  return (
    <SearchBarContainer>
      <h1>Organizations Table</h1>
      <SearchInput
        type="text"
        placeholder="Click Enter To Search Organizations By Name..."
        onChange={(e) => setLocalSearchTerm(e.target.value)}
        value={localSearchTerm}
        title="Click Enter to search"
        onKeyDown={handleKeyPress}
      />
      <Button
        variant="contained"
        startIcon={<FileDownloadIcon />}
        onClick={handleDownloadCSV}
        sx={{
          backgroundColor: "green",
          color: "#fff",
          width: "250px",
          height: "45px",
          "&:hover": {
            backgroundColor: "#32cd32", // LimeGreen
          },
        }}
      >
        Save to Excel
      </Button>
    </SearchBarContainer>
  );
};

export default SearchBar;
