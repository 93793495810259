import React from "react";
import MultipleMedia from "./MultipleMedia.js";
import SingleMedia from "./SingleMedia.js";
import Preview from "./Preview.js";

function ContentDisplay({
  showPreview,
  preview_title,
  preview_image,
  images,
  videos,
}) {
  const renderImages = () => {
    if (showPreview && preview_title != null) return;

    if (!Array.isArray(images) || !Array.isArray(videos)) {
      throw new Error("Both images and videos should be defined arrays.");
    }

    const maxLength = Math.max(images.length, videos.length);
    const media = [];

    for (let i = 0; i < maxLength; i++) {
      if (i < images.length) {
        media.push({ image: images[i] });
      }
      if (i < videos.length) {
        media.push({ video: videos[i][0] });
      }
    }

    switch (media.length) {
      case 0:
        return null;
      case 1:
        return <SingleMedia media={media[0]} alt="post image" />;
      default:
        return <MultipleMedia media={media} />;
    }
  };

  return (
    <>
      {<Preview preview_image={preview_image} preview_title={preview_title} />}
      {renderImages()}
    </>
  );
}

export default ContentDisplay;
