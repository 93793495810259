import React, { useState, useEffect } from "react";
import { ContentRow } from "../ContentRow";
import { Button } from "@mui/material";
import { getMyProfile, deactivateMyAccount } from "./Profile_utils";
import PasswordChangePopup from "./ProfileForm";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import "../../../../assets/css/edtitableContentRow.css";
import { EditableContentRow } from "./EditableContentRow";

const Profile = () => {
  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    const getProfile = async () => {
      setIsLoading(true);
      try {
        const result = await getMyProfile();
        setProfile({
          firstName: result.first_name,
          lastName: result.last_name,
          email: result.email,
          phone: result.phone,
        });
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch profile:", error);
        setIsLoading(false);
      }
    };

    getProfile();
  }, []);

  const deactivateAccount = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, deactivate it!",
    });

    if (result.isConfirmed) {
      try {
        const response = await deactivateMyAccount();
        if (!response.status === "success") {
          await Swal.fire({
            icon: "error",
            title: "Failed to deactivate account",
            text: "Please try again later",
          });
        }
        return Swal.fire({
          icon: "success",
          title: "Account Deactivated",
          text: "Your account has been successfully deactivated",
        }).then(() => {
          window.location.href = "/signin";
        });
      } catch (error) {
        console.error("Failed to deactivate account:", error);
        throw error;
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <EditableContentRow
            title={"First Name"}
            value={profile.firstName || ""}
            attributeKey={"first_name"}
          />
          <EditableContentRow
            title={"Last Name"}
            value={profile.lastName || ""}
            attributeKey={"last_name"}
          />
          <EditableContentRow
            title={"Phone Number"}
            value={profile.phone || ""}
            attributeKey={"phone"}
          />
          <ContentRow title={"Email"}>{profile.email}</ContentRow>
          <ContentRow title={"Reset Password"}>
            <Button
              onClick={handleOpenPopup}
              sx={{
                height: "39px",
                width: "140px",
                fontWeight: "inherit",
                fontSize: "14px",
                backgroundColor: "#007bff",
                color: "white",
                "&:hover": {
                  backgroundColor: "#002379",
                },
                borderRadius: "4px",
                textTransform: "none",
              }}
            >
              Change Password
            </Button>
          </ContentRow>
          <PasswordChangePopup isOpen={showPopup} onClose={handleClosePopup} />

          <ContentRow title={"Account Options"}>
            <Button
              variant="contained"
              color="error"
              sx={{
                height: "39px",
                width: "140px",
                zIndex: 1,
                fontWeight: "inherit",
                textTransform: "none",
                fontSize: "14px",
                borderRadius: "4px",
              }}
              onClick={deactivateAccount}
            >
              Deactivate Now
            </Button>
          </ContentRow>
        </>
      )}
    </>
  );
};
export default Profile;
