import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "./components/themes/ThemeContext";
import SideBar from "./components/navbar/SideBar.js";
import GlobalStyle from "./global/GlobalStyle.js";
import { EventSourceProvider } from "./context/EventSourceContext";
import SignIn from "./components/authentication/SignIn/SignIn.js";
import SignUp from "./components/authentication/SignUp/SignUp.js";
// import Verify from "./components/authentication/VerifyEmail/Verify.js";
import ResetPassword from "./components/authentication/PasswordReset/passResetForm.js";
import { ColumnsProvider } from "./components/ColumnsContext";
import ColumnsContainer from "./components/ColumnsContainer";
import { useAuth } from "./components/authentication/AuthContext/AuthContext.js";
import { openDb } from "./components/localdb/DBUtils.js";
import LoadingSpinner from "./components/LoadingSpinner.js";
import Map from "./components/map/Map.js";
import AdminRoutes from "./admin/AdminRoutes.js";
import BookmarkEditor from "./components/post/bookmarks/BookmarkEditor.js";

function App() {
  const { isAuthenticated, role, isLoading } = useAuth();

  document.addEventListener("DOMContentLoaded", function () {
    openDb()
      .then(() => {})
      .catch((error) => {
        console.error("Error initializing database:", error);
      });
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const ProtectedRoute = ({ children, onlyVerified }) => {
    // Redirect unauthenticated users to the sign-in page
    if (!isAuthenticated) {
      return <Navigate to="/signin" replace />;
    }
    // Redirect users who are authenticated but not verified to the verify page if onlyVerified flag is true
    // if (onlyVerified && !isVerified) {
    //   return <Navigate to="/verify" replace />;
    // }
    return children;
  };

  const ProtectedAuthRoutes = ({ children, onlyVerified }) => {
    if (isAuthenticated) {
      if (role === "admin") {
        return <Navigate to="/admin/users" replace />;
      } else if (role === "business") {
        return <Navigate to="/business/home" replace />;
      }

      return <Navigate to="/" replace />;
    }

    // Redirect users who are authenticated but not verified to the verify page if onlyVerified flag is true
    // if (onlyVerified && !isVerified) {
    //   return <Navigate to="/verify" replace />;
    // }

    return children;
  };

  const AdminAuthRoutes = ({ children }) => {
    if (role === "user") {
      return <Navigate to="/" replace />;
    }
    return children;
  };

  const BusinessRoutes = ({ children }) => {
    if (role === "customer" || role === "admin") {
      return children;
    }
    return <Navigate to="/" replace />;
  };

  console.log("Role:", role);

  return (
    <ThemeProvider>
      <BrowserRouter>
        <div className="App">
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute onlyVerified>
                  <InnerApp />
                </ProtectedRoute>
              }
            />
            <Route
              path="/bookmark-editor"
              element={
                <ProtectedRoute onlyVerified>
                  <BookmarkEditor />
                </ProtectedRoute>
              }
            />
            <Route
              path="/map"
              element={
                <BusinessRoutes onlyVerified>
                  <Map />
                </BusinessRoutes>
              }
            />
            <Route
              path="/signin"
              element={
                <ProtectedAuthRoutes>
                  <SignIn />
                </ProtectedAuthRoutes>
              }
            />
            <Route
              path="/signup"
              element={
                <ProtectedAuthRoutes>
                  <SignUp />
                </ProtectedAuthRoutes>
              }
            />
            {/* <Route path="/verify" element={<ProtectedRoute><Verify /></ProtectedRoute>}/> */}
            <Route
              path="/reset-password"
              element={
                <ProtectedAuthRoutes>
                  <ResetPassword />
                </ProtectedAuthRoutes>
              }
            />
            {/* <Route path="/bundles" element={<Bundle />} /> */}

            {/* <Route
              path="/admin/*"
              element={
                <AdminAuthRoutes>
                  <AdminLayout />
                </AdminAuthRoutes>
              }
            /> */}
            <Route
              path="/admin/*"
              element={
                <AdminAuthRoutes>
                  <AdminRoutes />
                </AdminAuthRoutes>
              }
            />
          </Routes>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

const InnerApp = () => {
  return (
    <>
      <EventSourceProvider>
        <GlobalStyle />
        <ColumnsProvider>
          <SideBar>
            <ColumnsContainer />
          </SideBar>
        </ColumnsProvider>
      </EventSourceProvider>
    </>
  );
};

export default App;
