import React, { createContext, useEffect, useRef } from "react";
import { getAuth, webSse } from "../globalUtils";

export const EventSourceContext = createContext({
  subscribe: () => {},
  unsubscribe: () => {},
});

export const EventSourceProvider = ({ children }) => {
  const eventSourceRef = useRef(null);
  const subscribers = useRef(new Set());

  // Function to fetch SSE token using the access token
  const fetchSseToken = async () => {
    try {
      const response = await getAuth("/auth/sse-token", {});
      const data = await response.json();
      connectEventSource(data.sseToken); // Connect with the new token
    } catch (error) {
      console.error("Failed to fetch SSE token with access token:", error);
    }
  };

  // Function to initialize or reconnect the EventSource with the new token
  const connectEventSource = (token) => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close(); // Close existing connection if open
    }

    const eventSource = new EventSource(`${webSse}/events?token=${token}`);
    eventSource.onmessage = function (event) {
      const newMessage = JSON.parse(event.data);

      // Check if newMessage.message is null or undefined
      if (newMessage.message == null) {
        // This covers both null and undefined
        console.warn(
          "Received null or undefined message, refreshing the page..."
        );
        window.location.reload(); // Force a refresh of the page
      } else {
        subscribers.current.forEach((handler) => handler(newMessage.message));
      }
    };

    eventSource.onerror = function (err) {
      console.error("EventSource failed:", err);
      eventSource.close();
      // Reconnect with a new token after a delay
      setTimeout(fetchSseToken, 5000);
    };

    eventSourceRef.current = eventSource;
  };

  useEffect(() => {
    fetchSseToken(); // Fetch token and connect on mount

    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, []);

  const subscribe = (handler) => {
    subscribers.current.add(handler);
  };

  const unsubscribe = (handler) => {
    subscribers.current.delete(handler);
  };

  return (
    <EventSourceContext.Provider value={{ subscribe, unsubscribe }}>
      {children}
    </EventSourceContext.Provider>
  );
};
