import React, { useState, useEffect, useMemo } from "react";
import { useBookmarks } from "../bookmarks/BookmarkContext";
import Post from "../Post";
import Popup from "../popup";
import { deletePost } from "../../localdb/DBUtils";
import styled from "styled-components";
import { post } from "../../../globalUtils";
import Spinner from "../../../Spinner";
import SearchComponent from "./SearchTab";
import ExportCsv from "./ExportCsv";
import { useNavigate } from "react-router-dom";

const ToggleButton = styled.button`
  position: fixed;
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  right: ${(props) => (props.$show ? "330px" : "-8px")};
  top: ${(props) =>
    props.$top}px; /* Use $ prefix to make it a transient prop */
  z-index: 21;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  width: 60px;
  transition: right 0.3s ease; /* Add animation */

  &:hover {
    background-color: ${({ theme }) => theme.cancel_hover};
  }

  span {
    margin-left: 8px;
    color: #333;
    font-size: 16px;
  }
`;

const BookmarkList = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  height: 100vh;
  padding: 20px;
  background-color: ${({ theme }) => theme.column_background};
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 22;
  transform: translateX(
    ${(props) => (props.$show ? "0" : "100%")}
  ); /* Use $ prefix to make it a transient prop */
  transition: transform 0.3s ease;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.chip_inactive};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.background};
    border-radius: 10px;
    border: 3px solid ${({ theme }) => theme.chip_inactive};
  }
`;

export const defaultColumn = {
  get_video: true,
  get_image: true,
  get_preview: true,
  get_text_only: true,
};

const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: none;
  border: none;
  color: ${({ theme }) => theme.button_text};
  font-size: 15px;
  border: none;
  cursor: pointer;
  margin-left: 8px;
`;

const HeaderContainer = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 25px;
  gap: 20px;
`;

const EditorButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Space between icon and text */
  padding: 10px 20px;
  border: 2px solid #1e2836;
  background-color: white;
  cursor: pointer;
  color: #1e2836;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  width: 95%;

  &:hover {
    background-color: #1e2836;
    color: white;
  }

  .material-icons {
    font-size: 20px; /* Size of the icon */
  }
`;

const DownloadButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: none;
  border: none;
  color: ${({ theme }) => theme.button_text};
  font-size: 15px;
  border: none;
  cursor: pointer;
  margin-left: 8px;
`;

export const ThemedInput = styled.input`
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border-color: ${({ theme }) => theme.border_color};
  width: 95%;
  margin: 0 auto;
  padding: 10px 20px;
`;

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; /* Adjust the margin as needed */
`;

export const downloadBlob = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const BookmarkComponent = ({ show }) => {
  const { bookmarkedPosts, deleteContextPost } = useBookmarks();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentId, setCurrentPost] = useState("");
  const [currentsender, setCurrentSender] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const closePopup = () => setIsPopupOpen(false);

  const handleOpenPopup = (sender_username, post_id) => {
    setCurrentSender(sender_username);
    setCurrentPost(post_id);
    setIsOpen(true);
  };

  const deleteBookmarks = () => {
    if (window.confirm("Are you sure you want to delete all bookmarks?")) {
      bookmarkedPosts.forEach((post) => {
        deleteContextPost(post.post_id, post.main_id);
        deletePost(post.post_id);
      });
    }
  };

  const downloadMedia = async () => {
    try {
      setLoading(true);
      const post_ids = bookmarkedPosts.map((post) => post.id.toString());
      if (post_ids.length === 0) {
        alert("No posts to download.");
        return;
      }

      const response = await post("/post/downloadMedia", post_ids);

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const blob = await response.blob();

      if (blob.size > 0) {
        downloadBlob(blob, "teledeck_media.zip");
      } else {
        console.error("Received an empty file.");
      }
    } catch (error) {
      console.error("Failed to download file:", error);
    } finally {
      setLoading(false);
    }
  };

  const filteredPosts = useMemo(() => {
    return bookmarkedPosts.filter((post) => {
      return post.text.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [bookmarkedPosts, searchTerm]);

  return (
    <>
      {isOpen && (
        <Popup
          senderusername={currentsender}
          post_id={currentId}
          onClose={() => setIsOpen(false)}
        />
      )}
      <BookmarkList $show={show}>
        {" "}
        {/* Use $ prefix to make it a transient prop */}
        <h2 style={{ textAlign: "center" }}>Bookmarked Posts</h2>
        <HeaderContainer>
          <ExportCsv bookmarkedPosts={bookmarkedPosts} />
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <DownloadButton onClick={downloadMedia}>
              <i className="material-icons">download</i>Download
            </DownloadButton>
          )}
          <DeleteButton onClick={deleteBookmarks}>
            <i className="material-icons">delete</i>Clear
          </DeleteButton>
        </HeaderContainer>
        <CenteredDiv>
          <EditorButton
            onClick={() => {
              navigate("/bookmark-editor");
            }}
          >
            <span className="material-icons">edit_document</span>Go To Editor
          </EditorButton>
        </CenteredDiv>
        <div className="draggable-input-icon-container">
          <ThemedInput
            type="text"
            className="ColumnNameInput TextArea"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <span className="material-icons search-icon">search</span>
        </div>
        {filteredPosts.length > 0 ? (
          filteredPosts.map((post) => (
            <div key={post.post_id}>
              <Post
                post={post}
                column={defaultColumn}
                is_a_bookmark={true}
                onOpenPopup={handleOpenPopup}
              />
            </div>
          ))
        ) : (
          <p style={{ marginLeft: "10px" }}>No bookmarks yet.</p>
        )}
      </BookmarkList>
    </>
  );
};
export const BookmarkPopup = ({ columns }) => {
  const [showBookmark, setShowBookmark] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [top, setTop] = useState(70);
  const [top2, setTop2] = useState(120);
  const [isDragging, setIsDragging] = useState(false);
  const [isDragging2, setIsDragging2] = useState(false);
  const [startY, setStartY] = useState(0);
  const [startY2, setStartY2] = useState(0);

  const toggleBookmarks = () => {
    setShowBookmark((prev) => !prev);
  };

  const toggleSearch = () => {
    setShowSearch((prev) => !prev);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartY(e.clientY - top);
    e.preventDefault();
  };

  const handleMouseDown2 = (e) => {
    setIsDragging2(true);
    setStartY2(e.clientY - top2);
    e.preventDefault();
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const newTop = e.clientY - startY;
      const windowHeight = window.innerHeight;
      const boundedTop = Math.max(0, Math.min(newTop, windowHeight - 50));
      setTop(boundedTop);
      e.preventDefault();
    }
    if (isDragging2) {
      const newTop2 = e.clientY - startY2;
      const windowHeight = window.innerHeight;
      const boundedTop2 = Math.max(0, Math.min(newTop2, windowHeight - 50));
      setTop2(boundedTop2);
      e.preventDefault();
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setIsDragging2(false);
  };

  useEffect(() => {
    if (isDragging || isDragging2) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, isDragging2, startY, startY2]);

  return (
    <div>
      <ToggleButton
        $top={top}
        $show={showBookmark}
        onMouseDown={handleMouseDown}
        onClick={toggleBookmarks}
      >
        <i className="material-icons">bookmarks</i>
      </ToggleButton>
      <ToggleButton
        $top={top2}
        $show={showSearch}
        onMouseDown={handleMouseDown2}
        onClick={toggleSearch}
      >
        <i className="material-icons">search</i>
      </ToggleButton>
      <BookmarkComponent show={showBookmark} />
      <SearchComponent show={showSearch} columns={columns} />
    </div>
  );
};
