import { useEffect, useState } from "react";

const AreaProgressChart = () => {
  const [serviceData, setServiceData] = useState(null);

  useEffect(() => {
    // Mock data representing news categories and their post percentages
    const mockData = [
      { id: 1, name: "Politics", percentValues: 40 },
      { id: 2, name: "Technology", percentValues: 25 },
      { id: 3, name: "Sports", percentValues: 15 },
      { id: 4, name: "Entertainment", percentValues: 10 },
      { id: 5, name: "Health", percentValues: 10 },
    ];
    setServiceData(mockData);
  }, []);

  return (
    <div className="progress-bar">
      <div className="progress-bar-info">
        <h4 className="progress-bar-title">Number of Posts by Category</h4>
      </div>
      {serviceData ? (
        <div className="progress-bar-list">
          {serviceData.map((progressbar) => (
            <div className="progress-bar-item" key={progressbar.id}>
              <div className="bar-item-info">
                <p className="bar-item-info-name">{progressbar.name}</p>
                <p className="bar-item-info-value">
                  {progressbar.percentValues}%
                </p>
              </div>
              <div className="bar-item-full">
                <div
                  className="bar-item-filled"
                  style={{ width: `${progressbar.percentValues}%` }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>Loading service data...</p>
      )}
    </div>
  );
};

export default AreaProgressChart;
