import React, { useState, useEffect } from "react";
import Select from "react-select";
import styled from "styled-components";
import { getAuth, postAuth } from "../../../../../globalUtils";
import Table from "./Table";

// Styled components
const Section = styled.section`
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  outline: none;

  &:focus {
    border-color: #007bff;
  }
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const AreaTable = () => {
  const [email, setEmail] = useState("");
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAuth(`/organization`);
        const responseData = await response.json();
        const organizationOptions = responseData.data.map((org) => ({
          value: org.id,
          label: org.name,
        })); // Convert to key-value array
        setOrganizations(organizationOptions);
      } catch (error) {
        console.error("Failed to fetch organizations:", error);
        setOrganizations([]); // Clear filtered data if fetch fails
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await postAuth(`/organization/add`, {
        email: email,
        organization_id: selectedOrganization.value,
      });
      const responseData = await response.json();
      if (responseData.success) {
        alert("User assigned successfully");
      } else {
        alert(responseData.message);
      }
    } catch (error) {
      console.error("Failed to assign user:", error);
    }
  };

  return (
    <>
      <Section>
        <h1
          style={{
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Assign User to Organization
        </h1>
        <Form onSubmit={handleSubmit}>
          <p
            style={{
              marginBottom: "30px",
              textAlign: "center",
            }}
          >
            This is if the user signs up but doesnt enter or have an
            organization
          </p>
          <FormGroup>
            <Label htmlFor="email">
              Enter The User Email (case-sensitive):
            </Label>
            <Input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="organization">Select Organization To Add To:</Label>
            <Select
              id="organization"
              options={organizations}
              value={selectedOrganization}
              onChange={setSelectedOrganization}
              required
            />
          </FormGroup>
          <Button type="submit">Assign User</Button>
          <p
            style={{
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            Note: This will also verify the user
          </p>
        </Form>
      </Section>
      <section className="content-area-table">
        <Table />
      </section>
    </>
  );
};

export default AreaTable;
