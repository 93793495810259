import React, { useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { useTheme } from "../themes/ThemeContext";
import zIndex from "@mui/material/styles/zIndex";

// New styles for modal content
const StyledModal = styled(Modal)`
  background-color: ${({ theme }) => (theme === "dark" ? "#333" : "white")};
  padding: 20px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 30%;
  max-width: 300px;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  h2 {
    padding-left: 0;
  }
`;

const ModalButton = styled.button`
  padding: 10px 20px;
  margin: 10px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

// Specific style for the Export button
const ExportButton = styled(ModalButton)`
  background-color: #218838; // Green
  &:hover {
    background-color: #218838; // Dark green
  }
`;

// Specific style for the Cancel button
const CancelButton = styled(ModalButton)`
  background-color: #c82333; // Dark red
  &:hover {
    background-color: #dc3545; // Light red
  }
`;

// Style for the language select dropdown
const LanguageSelect = styled.select`
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.borderColor};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

// Custom styles for the modal overlay
const customOverlayStyles = {
  overlay: {
    zIndex: 1000,
  },
};

// Set the root element for accessibility for the modal
Modal.setAppElement("#root");

const TranslateColumn = ({ setLanguage, isOpen, setIsOpen }) => {
  const [userLanguage, setUserLanguage] = useState("original");
  const { theme } = useTheme();

  const closeModal = () => setIsOpen(false);

  const handleLanguageChange = (event) => {
    setUserLanguage(event.target.value);
  };

  const handleLangSelect = () => {
    setLanguage(userLanguage);
    closeModal();
  };

  return (
    <>
      <StyledModal
        isOpen={isOpen}
        onRequestClose={closeModal}
        theme={theme}
        style={customOverlayStyles}
      >
        <h2>Select Language </h2>
        <LanguageSelect
          value={userLanguage}
          onChange={handleLanguageChange}
          theme={theme}
        >
          <option value="original">Original</option>
          <option value="en">English (United States)</option>
          <option value="fr">French (France)</option>
          <option value="es">Spanish (Spain)</option>
          <option value="ar">Arabic</option>
        </LanguageSelect>
        <ButtonContainer>
          <ExportButton
            onClick={() => {
              handleLangSelect();
            }}
            theme={theme}
          >
            Translate
          </ExportButton>
          <CancelButton onClick={closeModal} theme={theme}>
            Cancel
          </CancelButton>
        </ButtonContainer>
      </StyledModal>
    </>
  );
};

export default TranslateColumn;
