import React from "react";
import styled from "styled-components";

const MediaGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
  overflow: hidden;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

const MediaContainer = styled.div`
  width: 138px;
  height: 138px;
  position: relative;
  margin-top: 5px;
  margin-left: 4px;
  &:nth-child(even) {
    margin-left: 5px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.play_overlay_color};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 64px;
  box-sizing: border-box;
  border-radius: 8px;

  color: ${({ theme }) => theme.border_color};

  &.extraItems {
    background-color: rgb(5, 5, 5, 0.9);
    font-size: 26px;
  }
`;

const Icon = styled.i`
  font-size: 56px;
  color: ${({ theme }) => theme.body};
  border: 2px solid ${({ theme }) => theme.body};
  border-radius: 36px;
  background-color: ${({ theme }) => theme.play_background};
`;

function MultipleMedia({ media }) {
  const numberOfExtraImages = media.length - 4;

  return (
    <MediaGrid>
      {media.slice(0, 4).map((item, index) => (
        <MediaContainer key={index}>
          {item.image ? (
            <StyledImage src={item.image} alt={`image ${index}`} />
          ) : (
            <>
              <Overlay>
                <Icon className="material-icons">play_arrow</Icon>
              </Overlay>
              <StyledImage src={item.video} alt={`video ${index}`} />
            </>
          )}
          {/* Show overlay on the last item if there are more than 4 media */}
          {index === 3 && numberOfExtraImages > 0 && (
            <Overlay className="extraItems">+{numberOfExtraImages}</Overlay>
          )}
        </MediaContainer>
      ))}
    </MediaGrid>
  );
}

export default MultipleMedia;
