import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Swal from "sweetalert2";
import { useTheme } from "../themes/ThemeContext";
import { postAuth } from "../../globalUtils";
import Spinner from "../../Spinner";
import Select from "react-select";
import { DatePicker } from "../DraggableColumn";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
`;

export const Modal = styled.div`
  background-color: ${({ theme }) => (theme === "dark" ? "#333" : "white")};
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const CloseButton = styled.button`
  float: right;
  border: none;
  background: none;
  font-size: 30px;
  cursor: pointer;
  margin-top: 10px;
`;

export const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #1e2836;
  color: white;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
`;

export const Input = styled.input`
  padding: 8px 40px 8px 8px;
  margin-top: 5px;
  border: ${({ theme }) =>
    theme === "dark" ? "1px solid #555" : "1px solid #ccc"};
  border-radius: 5px;
  background-color: ${({ theme }) => (theme === "dark" ? "#222" : "white")};
  color: ${({ theme }) => (theme === "dark" ? "#ddd" : "#333")};
`;

export const RemoveButton = styled.button`
  display: flex;
  margin-left: 10px;
  background-color: ${({ theme }) => (theme === "dark" ? "#666" : "#dc3545")};
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => (theme === "dark" ? "#555" : "#c82333")};
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 15px;
`;

export const CheckBoxInput = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const SectionTitle = styled.h4`
  margin-top: 20px;
  color: ${({ theme }) => theme.text}; // Assuming theme.text is defined
`;

const SectionContainer = styled.div`
  padding: 10px;
  border-top: 1px solid ${({ theme }) => theme.border}; // Assuming theme.border is defined
  margin-top: 20px;
`;

const StyledSelect = styled(Select)`
  font-size: 14px;
  width: 100%;
`;

const sanitizeFilename = (name) => {
  // Remove invalid file name characters regex
  return name.replace(/[/\\?%*:|"<>]/g, "-");
};

const FromToPopup = ({ isOpen, onClose, search, column }) => {
  const [From, setFrom] = useState("");
  const [To, setTo] = useState("");
  const { theme } = useTheme();
  const [CSVloading, setCSVLoading] = useState(false);
  const [MediaLoading, setMediaLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({
    value: "Original",
    label: "Original",
  });

  const [mediaSelection, setMediaSelection] = useState({
    videos: false,
    images: false,
  });

  const toggleSelection = (mediaType) => {
    setMediaSelection((prevState) => ({
      ...prevState,
      [mediaType]: !prevState[mediaType],
    }));
  };

  const handleFromChange = (e) => {
    setFrom(e.target.value);
  };

  const handleToChange = (e) => {
    setTo(e.target.value);
  };

    const handleLanguageChange = (selectedOption) => {
      setSelectedLanguage(selectedOption);
    };

  const handleSubmit = async () => {
    try {
      setCSVLoading(true);
      if (From === "" || To === "") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please fill all fields",
        });
        return;
      }

      const from = new Date(From);
      const to = new Date(To);
      if (from >= to) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "From date should be before To date",
        });
        return;
      }

      // Send to backend
      const response = await postAuth("/post/download-csv", {
        from: from.toISOString(),
        to: to.toISOString(),
        searchString: search,
        column_id: column,
        language: selectedLanguage.value,
      });

      if (response.ok) {
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = downloadUrl;

        const filename = search
          ? `${sanitizeFilename(search)}_posts.csv`
          : "filtered_posts.csv";
        a.download = filename;

        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(downloadUrl);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "File has been downloaded successfully.",
        });
      } else {
        const errorData = await response.json();
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorData.message || "Failed to download file.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Unexpected Error",
        text: "An unexpected error occurred. Please try again.",
      });
      console.error("Error during handleSubmit:", error);
    } finally {
      setCSVLoading(false);
    }
  };

  const handleDownload = async () => {
    try {
      setMediaLoading(true);

      if (From === "" || To === "") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please fill all fields",
        });
        return;
      }

      const from = new Date(From);
      const to = new Date(To);
      if (from >= to) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "From date should be before To date",
        });
        return;
      }

      if (!mediaSelection.images && !mediaSelection.videos) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please select at least one type of media",
        });
        return;
      }

      // Send to backend
      const response = await postAuth("/post/download-column-media", {
        from: from.toISOString(),
        to: to.toISOString(),
        searchString: search,
        mediaSelection,
        column_id: column,
      });

      if (response.ok) {
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");

        a.href = downloadUrl;
        a.download = "media.zip";

        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(downloadUrl);

        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Media has been downloaded successfully.",
        });
      } else {
        const errorData = await response.json();
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorData.message || "Failed to download media.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Unexpected Error",
        text: "An unexpected error occurred. Please try again.",
      });
      console.error("Error during handleDownload:", error);
    } finally {
      setMediaLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <Overlay>
      <Modal theme={theme}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <h3>Download Options</h3>

        {/* Shared Date Range Inputs */}
        <SectionContainer>
          <SectionTitle>Set Date Interval</SectionTitle>
          <InputContainer>
            <label>From</label>
            <DatePicker
              className="date-time-picker"
              type="datetime-local"
              $theme={theme}
              value={From}
              onChange={handleFromChange}
              style={{ marginTop: "10px", marginBottom: "10px" }}
            />
          </InputContainer>
          <InputContainer>
            <label>To</label>
            <DatePicker
              className="date-time-picker"
              type="datetime-local"
              $theme={theme}
              value={To}
              onChange={handleToChange}
              style={{ marginTop: "10px", marginBottom: "10px" }}
            />
          </InputContainer>
        </SectionContainer>

        {/* CSV Download Section */}
        <SectionContainer>
          <SectionTitle>Download CSV</SectionTitle>

          <p>Choose Text Language</p>
          <InputContainer>
            <StyledSelect
              options={[
                { value: "Original", label: "Original" },
                { value: "en", label: "English" },
                { value: "ar", label: "Arabic" },
                { value: "es", label: "Spanish" },
                { value: "fr", label: "French" },
                { value: "de", label: "German" },
                { value: "ru", label: "Russian" },
              ]}
              value={selectedLanguage}
              onChange={handleLanguageChange}
            />
          </InputContainer>

          {CSVloading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </div>
          ) : (
            <SubmitButton
              theme={theme}
              onClick={handleSubmit}
              disabled={CSVloading}
            >
              Download CSV
            </SubmitButton>
          )}
        </SectionContainer>

        {/* Media Download Section */}
        <SectionContainer>
          <SectionTitle>Download Media</SectionTitle>
          <RadioContainer>
            <CheckBoxInput>
              <input
                type="checkbox"
                id="videos"
                name="media"
                checked={mediaSelection.videos}
                onChange={() => toggleSelection("videos")}
              />
              <label htmlFor="videos">Videos</label>
            </CheckBoxInput>
            <CheckBoxInput>
              <input
                type="checkbox"
                id="images"
                name="media"
                checked={mediaSelection.images}
                onChange={() => toggleSelection("images")}
              />
              <label htmlFor="images">Images</label>
            </CheckBoxInput>
          </RadioContainer>
          {MediaLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Spinner />
            </div>
          ) : (
            <SubmitButton
              theme={theme}
              onClick={handleDownload}
              disabled={MediaLoading}
            >
              Download Media
            </SubmitButton>
          )}
        </SectionContainer>
      </Modal>
    </Overlay>
  );
};

export default FromToPopup;
