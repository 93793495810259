import React, { useState } from "react";
import Swal from "sweetalert2";
import {
  comparePasswords,
  validatePassword,
} from "../../../authentication/SignUp/SignUp_utils";
import { changeMyPassword } from "./Profile_utils";

// Styled components
import styled from "styled-components";
import {
  Overlay,
  Modal,
  CloseButton,
  Input,
  SubmitButton,
  InputContainer,
} from "../Contact/ContactForm";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTheme } from "../../../themes/ThemeContext";

export const VisibilityButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  position: absolute;
  right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  color: inherit;
`;

const PasswordChangePopup = ({ isOpen, onClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
  const { theme } = useTheme();
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const toggleOldPasswordVisibility = () =>
    setIsOldPasswordVisible(!isOldPasswordVisible);
  const toggleNewPasswordVisibility = () =>
    setIsNewPasswordVisible(!isNewPasswordVisible);
  const toggleConfirmPasswordVisibility = () =>
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "oldPassword") setOldPassword(value);
    if (name === "newPassword") setNewPassword(value);
    if (name === "confirmPassword") setConfirmPassword(value);
  };
  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (validatePassword(newPassword) !== "") {
        await Swal.fire({
          icon: "error",
          title: "Invalid Password",
          text: "Password must have at least 8 characters, 1 capital letter, 1 special character, and 1 number",
        });
        return;
      }
      const comparison = comparePasswords(newPassword, confirmPassword);
      if (comparison !== "") {
        await Swal.fire({
          icon: "error",
          title: "Invalid Password",
          text: comparison,
        });
        return;
      }
      const response = await changeMyPassword(oldPassword, newPassword);
      if (response.ok) {
        await Swal.fire({
          icon: "success",
          title: "Password changed successfully",
        });
        handleClose();
        window.location.href = "/signin";
        onClose();
      } else {
        await Swal.fire({
          icon: "error",
          title: "Failed to change password",
          text: response.message,
        });
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      await Swal.fire({
        icon: "error",
        title: "Failed to change password",
        text: "Please try again later",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setIsOldPasswordVisible(false);
    setIsNewPasswordVisible(false);
    setIsConfirmPasswordVisible(false);
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Overlay onClick={(e) => e.target === e.currentTarget && onClose()}>
      <Modal theme={theme} onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={handleClose}>&times;</CloseButton>
        <h3>Change Password</h3>
        <InputContainer>
          <Input
            name="oldPassword"
            type={isOldPasswordVisible ? "text" : "password"}
            placeholder="Enter old password"
            value={oldPassword}
            onChange={handleChange}
          />
          <VisibilityButton
            style={{ color: "black" }}
            onClick={toggleOldPasswordVisibility}
          >
            {isOldPasswordVisible ? <Visibility /> : <VisibilityOff />}
          </VisibilityButton>
        </InputContainer>
        {/* New Password Input */}
        <InputContainer>
          <Input
            name="newPassword"
            type={isNewPasswordVisible ? "text" : "password"}
            placeholder="Enter new password"
            value={newPassword}
            onChange={handleChange}
          />
          <VisibilityButton
            style={{ color: "black" }}
            onClick={toggleNewPasswordVisibility}
          >
            {isNewPasswordVisible ? <Visibility /> : <VisibilityOff />}
          </VisibilityButton>
        </InputContainer>
        {/* Confirm Password Input */}
        <InputContainer>
          <Input
            name="confirmPassword"
            type={isConfirmPasswordVisible ? "text" : "password"}
            placeholder="Confirm new password"
            value={confirmPassword}
            onChange={handleChange}
          />
          <VisibilityButton
            style={{ color: "black" }}
            onClick={toggleConfirmPasswordVisibility}
          >
            {isConfirmPasswordVisible ? <Visibility /> : <VisibilityOff />}
          </VisibilityButton>
        </InputContainer>

        <span style={{ fontWeight: "bold", fontSize: "1em" }}>
          Password must have:
        </span>
        <ul
          style={{
            listStyleType: "circle",
            marginLeft: "20px",
            marginTop: "5px",
          }}
        >
          <li style={{ marginBottom: "5px" }}>At least 8 characters</li>
          <li style={{ marginBottom: "5px" }}>1 capital letter</li>
          <li style={{ marginBottom: "5px" }}>1 special character</li>
          <li style={{ marginBottom: "5px" }}>1 number</li>
        </ul>
        <SubmitButton onClick={handleSubmit} disabled={isSubmitting}>
          {isSubmitting ? "Submitting" : "Submit"}
        </SubmitButton>
      </Modal>
    </Overlay>
  );
};

export default PasswordChangePopup;
