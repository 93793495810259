import { React, useState } from "react";
import {
  createNewColumn,
  getNextColumnPosition,
} from "../../utils/newColumnUtil";
import Swal from "sweetalert2";
import styled from "styled-components";
import { website } from "../../../globalUtils";
import { useColumns } from "../../ColumnsContext";

const ChannelItem = styled.div`
  &:hover {
    background-color: ${({ theme }) => theme.hover_background};
  }
`;

const ButtonItem = styled.button`
  background-color: ${({ theme }) => theme.chip_inactive};
  color: ${({ theme }) => theme.text};
  transition: ease 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.cancel_hover};
  }
`;

const ScrollableDiv = styled.div`
  /* Webkit browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.chip_inactive};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.background};
    border-radius: 10px;
    border: 3px solid ${({ theme }) => theme.chip_inactive}; /* Optional: adds space around the thumb */
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.background};
  ${({ theme }) => theme.chip_inactive};
`;

const ArrowItem = styled.button`
  color: ${({ theme }) => theme.border_color};
`;

const DoneButtonItem = styled.button`
  background-color: ${({ theme }) => theme.done_button};
  transition: ease 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.done_hover};
  }
`;

const FixedFooter = styled.div`
  background-color: ${({ theme }) => theme.popup_background};
  &:hover {
    background-color: ${({ theme }) => theme.hover_background};
  }
`;

const ThirdColumn = ({
  onClose,
  chosenChannels,
  onRemoveChannel,
  selectedIcon,
  hideButtons,
  deactivateRegularColumn,
  notificationEnabled,
  notificationSound,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { addColumn } = useColumns();

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const columnName = document
      .querySelector(".CHANNELNAMEINPUTNEW")
      .value.trim();
    const hasChannelElement = document.querySelectorAll(".Chanol").length > 0;

    if (!columnName) {
      Swal.fire({
        icon: "info",
        text: "Please provide a column name.",
      });
      setIsSubmitting(false);
      return;
    }

    if (!selectedIcon) {
      Swal.fire({
        icon: "info",
        text: "Please select an icon.",
      });
      setIsSubmitting(false);
      return;
    }

    if (!hasChannelElement) {
      Swal.fire({
        icon: "info",
        text: "Please choose at least one channel.",
      });
      setIsSubmitting(false);
      return;
    }

    const checkedButtons = {
      text:
        document.querySelector(".checkmark-button.checked")?.innerText ===
        "Text Only",
      images:
        document.querySelector(".checkmark-button.checked")?.innerText ===
        "Images",
      videos:
        document.querySelector(".checkmark-button.checked")?.innerText ===
        "Videos",
      links:
        document.querySelector(".checkmark-button.checked")?.innerText ===
        "Hyper Links",
    };

    try {
      const columnPosition = await getNextColumnPosition();
      const columnData = {
        name: columnName,
        icon: selectedIcon,
        notifications: notificationEnabled ? "1" : "0",
        isLoudSound: notificationSound,
        column_position: columnPosition,
        get_video: checkedButtons.videos ? "1" : "0",
        get_image: checkedButtons.images ? "1" : "0",
        get_preview: checkedButtons.links ? "1" : "0",
        get_text_only: checkedButtons.text ? "1" : "0",
        is_searchOnTop: document.querySelector(".Checkbox")?.checked
          ? "1"
          : "0",
        channels: chosenChannels.map((channel) => channel.id),
      };

      const result = await createNewColumn(columnData);

      if (result) {
        Swal.fire({
          icon: "success",
          text: "Column created successfully!",
        });

        // add the result.data.column here to the columns
        addColumn(result.column);

        onClose();
      } else {
        throw new Error("Failed to create column");
      }
    } catch (error) {
      console.error("Error creating column:", error);
      Swal.fire({
        icon: "error",
        text: "Failed to create column",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={deactivateRegularColumn ? "" : "RegularColumn"}>
      <div className="ChosenChannelsMain">
        <h3 className="ColumnHeader">Chosen Channels</h3>
        <ScrollableDiv className="ChannelsContainer">
          {chosenChannels.map((channel) => (
            <ChannelItem key={channel.id} className="Channel">
              <div className="ChannelElement Chanol">
                <div className="ChannelProfileandName">
                  <div>
                    <img
                      src={website + "/" + channel.image}
                      alt="Channel Icon"
                      className="ChannelIcon"
                    />
                  </div>
                  <div className="ChannelName">
                    <span className="channel_name">{channel.chat_title}</span>
                    <br />
                    <span className="channel_username">
                      @{channel.sender_username}
                    </span>
                  </div>
                </div>
                <button
                  className="CloseButton"
                  onClick={() => onRemoveChannel(channel)}
                >
                  <i className="material-icons">close</i>
                </button>
              </div>
            </ChannelItem>
          ))}
          {/* <FixedFooter className="FixedFooter">
                    <button className="AddAllButton">
                        Clear All
                        <i className="material-icons">close</i>
                    </button>
                </FixedFooter> */}
        </ScrollableDiv>
      </div>
      {!hideButtons && (
        <div className="ButtonContainer">
          <ButtonItem className="CancelButton ButtonLayout" onClick={onClose}>
            Cancel
          </ButtonItem>
          <DoneButtonItem
            className="DoneButton ButtonLayout"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Done
          </DoneButtonItem>
        </div>
      )}
    </div>
  );
};

export default ThirdColumn;
