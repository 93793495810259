import AreaCard from "./AreaCard";
import "./AreaCards.scss";
import { useEffect, useState } from "react";

const AreaCards = () => {
  const [serviceData, setServiceData] = useState(null);
  const [monthlyIncome, setMonthlyIncome] = useState(null);

  return (
    <section className="content-area-cards">
      <AreaCard
        colors={["#e4e8ef", "#f29a2e"]}
        percentFillValue={65}
        cardInfo={{
          title: "Number of Registered Users",
          value: 100,
          text: "Total number of users.",
        }}
      />
      <AreaCard
        colors={["#e4e8ef", "#475be8"]}
        percentFillValue={80}
        cardInfo={{
          title: "Sign Up Requests",
          value: 7,
          text: "Total number of riders today.",
        }}
      />
      <AreaCard
        colors={["#e4e8ef", "#4ce13f"]}
        percentFillValue={70}
        cardInfo={{
          title: "Channel Requests",
          value: 10,
          text: "Total income for this month.",
        }}
      />
    </section>
  );
};

export default AreaCards;
