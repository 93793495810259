import React, { useState } from "react";
import { ContentRow } from "../ContentRow";
import ChannelRequestPopup from "./ContactForm";
import { Button } from "@mui/material";

const Contact = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <ContentRow title={"Phone"}>+961-81-889-200</ContentRow>
      <ContentRow title={"Email"}>contact@cogdevelopers.com</ContentRow>
      <ContentRow title={"Request Extra Channels"}>
        <Button
          onClick={handleOpenPopup}
          sx={{
            height: "39px",
            width: "80px",
            fontWeight: "inherit",
            fontSize: "14px",
            backgroundColor: "#007bff", // Set your desired background color here
            color: "white", // Set text color
            "&:hover": {
              backgroundColor: "#002379", // Darker background on hover
            },
            borderRadius: "4px", // Rounded corners
            padding: "6px 12px", // Padding similar to Logout button
            textTransform: "none", // No uppercase
          }}
        >
          Request{" "}
        </Button>
      </ContentRow>
      <ChannelRequestPopup isOpen={showPopup} onClose={handleClosePopup} />
    </>
  );
};

export default Contact;
