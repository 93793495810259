import { AreaTable } from "../components";

const Users = () => {
  return (
    <div className="content-area">
      <AreaTable />
    </div>
  );
};

export default Users;
