// Create a context for all audio files in the specified directory
const audioContext = require.context("../../assets/audios", false, /\.mp3$/);

// Dynamically import all audio files into an object
export const audioFiles = audioContext.keys().reduce((files, file) => {
  const key = file.replace("./", "").replace(".mp3", "");
  files[key] = audioContext(file);
  return files;
}, {});

let currentAudio = null;

export const playSound = (isLoud) => {
  if (isLoud === "Off" || isLoud === "") {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
    }
    return;
  }

  const audioFile = audioFiles[isLoud];
  if (!audioFile) {
    console.warn(`No audio file found for key: ${isLoud}`);
    return;
  }

  if (currentAudio) {
    currentAudio.pause();
    currentAudio.currentTime = 0;
  }

  const audio = new Audio(audioFile);
  audio.onerror = (e) => {
    console.error(`Failed to load audio file: ${audioFile}`, e);
  };

  audio.play().catch((error) => {
    console.error(`Error playing audio file: ${audioFile}`, error);
  });

  currentAudio = audio;
};
