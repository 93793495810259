import { postAuth, getAuth } from '../../globalUtils';

export const createNewColumn = async (columnData) => {
    try {
        const response = await postAuth('/column/create', columnData);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error creating new column:', error);
        return null;
    }
};

export const getNextColumnPosition = async () => {
    try {
        const response = await getAuth('/column/');

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const columns = data.data;
        const maxPosition = Math.max(...columns.map(column => column.column_position), 0);
        return maxPosition + 1;
    } catch (error) {
        console.error('Error fetching columns:', error);
        return null;
    }
};
