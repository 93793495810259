import React, { useEffect, useRef } from "react";
import "./customToastStyle.css";
import { playSound } from "./playSound";

const CustomToast = ({
  columnTitle,
  postText,
  channelTitle,
  isLoudSound,
  postId,
}) => {
  const navItemsRef = useRef(null);
  const gradientRef = useRef(null);

  const handleScrollToColumn = (postId) => {
    const elementId = `post-${postId}`;
    const element = document.getElementById(elementId);

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });

      element.style.transition = "filter 1s";
      element.style.filter = "brightness(80%)";
      setTimeout(() => {
        element.style.filter = "none";
      }, 1000);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const element = navItemsRef.current;
      const scrollTop = element.scrollTop;
      const opacity = 1 - Math.min(1, scrollTop / 10);

      if (gradientRef.current) {
        gradientRef.current.style.opacity = opacity.toString();
      }
    };

    const navItemsElement = navItemsRef.current;
    if (navItemsElement) {
      navItemsElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (navItemsElement) {
        navItemsElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    playSound(isLoudSound);
  }, [isLoudSound]);

  return (
    <div
      className="custom-toast"
      ref={navItemsRef}
      onClick={() => {
        handleScrollToColumn(postId);
      }}
    >
      <strong ref={gradientRef}>
        {columnTitle} - {channelTitle}
      </strong>
      <p>{postText}</p>
    </div>
  );
};

export default CustomToast;
