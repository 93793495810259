import { Outlet } from "react-router-dom";
import { Sidebar } from "../components";
import { SidebarContext } from "../context/SidebarContext";
import { useContext } from "react";
import { MdOutlineNavigation } from "react-icons/md";
const BaseLayout = () => {
  return (
    <main className="page-wrapper">
      <Sidebar />
      <div className="content-wrapper">
        <Outlet />
      </div>
    </main>
  );
};

export default BaseLayout;
