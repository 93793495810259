import React, { useState } from "react";
import Subscription from "./SettingsContent/Subscription";
import Settings from "./SettingsContent/settings";
import Contact from "./SettingsContent/Contact/Contact";
import Profile from "./SettingsContent/Profile/Profile";

const categoryIcons = {
  Subscription: "subscriptions",
  General: "settings",
  Contact: "contact_page",
  Profile: "manage_accounts",
};

function SettingsContainer({ onClose }) {
  const [selectedCategory, setSelectedCategory] = useState("General");

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const renderContent = () => {
    switch (selectedCategory) {
      case "Subscription":
        return <Subscription />;
      case "General":
        return <Settings />;
      case "Contact":
        return <Contact />;
      case "Profile":
        return <Profile />;
      default:
        return <Subscription />;
    }
  };

  return (
    <div className="SettingsContainer">
      <div className="SettingsHeader">
        <h2>Settings</h2>
        <button className="CloseButton" onClick={onClose}>
          <i className="material-icons close">close</i>
        </button>
      </div>
      <hr className="ColumnDivider" />
      <div className="SettingsSection">
        <div className="SettingsCategories">
          <ul className="CategoryList">
            {["General", "Profile", "Subscription", "Contact"].map(
              (category) => (
                <li
                  key={category}
                  className={`CategoryListItem ${
                    selectedCategory === category ? "active" : ""
                  }`}
                  onClick={() => handleCategoryClick(category)}
                >
                  <span className="material-icons side">
                    {categoryIcons[category]}
                  </span>
                  {category}
                </li>
              )
            )}
          </ul>
        </div>
        <div className="SettingsContent">{renderContent()}</div>
      </div>
    </div>
  );
}

export default SettingsContainer;
