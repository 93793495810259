import { AreaCards, AreaCharts, AreaTable, AreaTop } from "../components";
import BusTripProgressChart from "../components/dashboard/areaCharts/BusTripProgressChart"
const Dashboard = () => {
  return (
    <div className="content-area">
      {/*<AreaTop />*/}
      <AreaCards />
      <AreaCharts />
      <BusTripProgressChart/>
    </div>
  );
};

export default Dashboard;
