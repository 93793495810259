import AreaTable from "../components/dashboard/areaTable/add-organization/AreaTable";

const AddOrg = () => {
  return (
    <div className="content-area">
      <AreaTable />
    </div>
  );
};
export default AddOrg;
