import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import { showErrorAlert } from "../SignUp/SignUp_utils";
import { validatePassword, comparePasswords } from "../SignUp/SignUp_utils";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import { resetPassword, validateResetToken } from "./passReset_utils";
import LoadingSpinner from "../../LoadingSpinner";
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="./about-us">
        Teledeck
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [ConfirmNewPassword, setConfirmNewPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isValidToken, setIsValidToken] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get("token");

  const [NpasswordError, setNPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => setShowPassword(!showPassword);

  const navigate = useNavigate();

  const handleNewPassChange = (event) => {
    const { value } = event.target;
    setNewPassword(value);
    setNPasswordError(validatePassword(event.target.value));
  };
  const handleConfirmNewPassChange = (event) => {
    const { value } = event.target;
    setConfirmNewPassword(value);
    setNPasswordError(comparePasswords(newPassword, value));
  };

  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await validateResetToken(token);

        if (response.success) {
          setIsValidToken(true);
        } else {
          alert("Invalid or expired token");
        }
      } catch (error) {
        alert("Failed to validate token");
      }
      setLoading(false);
    };

    validateToken();
  }, [token]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!isValidToken) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <h1>Invalid or expired token</h1>
      </div>
    );
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      const result = await resetPassword(
        token,
        newPassword,
        ConfirmNewPassword
      );

      if (result.success === true) {
        Swal.fire({
          icon: "success",
          text: "Your password has been changed successfully!",
        });
        navigate("/SignIn", { replace: true });
      } else {
        console.error("Password reset unsuccessful");
        showErrorAlert(result.message || "Password reset unsuccessful");
      }
    } catch (error) {
      console.error(
        "An unexpected error occurred during password reset:",
        error
      );
      showErrorAlert("An unexpected error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src=""
            alt="Your Logo"
            style={{ width: "80px", height: "80px", marginBottom: "20px" }}
          />
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <br />
          <br />
          <Typography fontSize={20} variant="body2" align="center">
            Please Enter Your New Password
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <Grid item xs={12} sx={{ mt: 3 }}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="new-password"
                value={newPassword}
                onChange={handleNewPassChange}
                onBlur={validatePassword}
                error={!!NpasswordError}
                helperText={NpasswordError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePasswordVisibility}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                mt: 3,
              }}
            >
              <TextField
                required
                fullWidth
                name="Cpassword"
                label="Confirm Password"
                type={showPassword ? "text" : "password"}
                id="Cpassword"
                autoComplete="Cpassword"
                value={ConfirmNewPassword}
                onChange={handleConfirmNewPassChange}
                onBlur={validatePassword}
                error={!!NpasswordError}
                helperText={NpasswordError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePasswordVisibility}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Button
              type="submit"
              disabled={isSubmitting}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </Button>

            <Copyright sx={{ mt: 4 }} />
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
