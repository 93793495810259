import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
} from "@mui/material";
import { Button as MuiButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteAuth } from "../../../../../globalUtils";

const ViewTeamPopup = ({ open, onClose, tags }) => {
  const deleteTag = async (id) => {
    try {
      const response = await deleteAuth(`/tag/${id}`);
      await response.json();
      if (response.ok) {
        alert("Tag deleted successfully");
      }
      onClose();
    } catch (error) {
      console.error("Failed to delete tag:", error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{ width: "400px", margin: "auto" }}
    >
      <DialogTitle>Channel Tags</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ textAlign: "center" }}>Tag Names</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tags.length > 0 ? (
                tags.map((tag) => (
                  <TableRow key={tag.id}>
                    <TableCell sx={{ textAlign: "center" }}>
                      {tag.name || "N/A"}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell sx={{ textAlign: "center" }} colSpan={1}>
                    No tags available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewTeamPopup;
