import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTheme } from "../../themes/ThemeContext";
import { DatePicker } from "../../DraggableColumn";
import { CheckBoxInput, RadioContainer } from "../../column_popups/FromToPopup";
import { useSearch } from "./SearchContext";
import Select from "react-select";
import Swal from "sweetalert2";
import { postAuth } from "../../../globalUtils";
import Spinner from "../../../Spinner";

export const ThemedInput = styled.input`
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border-color: ${({ theme }) => theme.border_color};
  width: 100%;
  margin: 0 auto;
  padding-left: 40px !important;
`;

const BookmarkList = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  height: 100vh;
  padding: 20px;
  background-color: ${({ theme }) => theme.column_background};
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 22;
  transform: translateX(
    ${(props) => (props.$show ? "0" : "100%")}
  ); /* Use $ prefix to make it a transient prop */
  transition: transform 0.3s ease;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.chip_inactive};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.background};
    border-radius: 10px;
    border: 3px solid ${({ theme }) => theme.chip_inactive};
  }
`;

const StyledSelect = styled(Select)`
  font-size: 14px;
  width: 100%;
`;

const SearchButton = styled.button`
  background-color: #1e2836;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
`;

const SectionTitle = styled.h4`
  color: ${({ theme }) => theme.text};
  margin: 10px;
  margin-left: 0;
`;

const SectionContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.border};
  width: 100%;
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const Input = styled.input`
  padding: 8px 40px 8px 8px;
  border: ${({ theme }) =>
    theme === "dark" ? "1px solid #555" : "1px solid #ccc"};
  border-radius: 5px;
  background-color: ${({ theme }) => (theme === "dark" ? "#222" : "white")};
  color: ${({ theme }) => (theme === "dark" ? "#ddd" : "#333")};
`;

export const SubmitButton = styled.button`
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  background-color: #1e2836;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const sanitizeFilename = (name) => {
  // Remove invalid file name characters regex
  return name.replace(/[/\\?%*:|"<>]/g, "-");
};

const SearchComponent = ({ show, columns }) => {
  const { theme } = useTheme();
  const [From, setFrom] = useState("");
  const [To, setTo] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [searchDate, setSearchDate] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState({
    value: "Original",
    label: "Original",
  });
  const [mediaSelection, setMediaSelection] = useState({
    videos: false,
    images: false,
  });
  const [selectedColumns, setSelectedColumns] = useState([]);

  const { setSearchState, setGlobalDate, setGlobalColumns } = useSearch();

  const [CSVloading, setCSVLoading] = useState(false);
  const [MediaLoading, setMediaLoading] = useState(false);

  const handleFromChange = (e) => {
    setFrom(e.target.value);
  };

  const handleToChange = (e) => {
    setTo(e.target.value);
  };

  const handleLanguageChange = (selectedOption) => {
    setSelectedLanguage(selectedOption);
  };
  const columnOptions = columns.map((column) => ({
    value: column.id,
    label: column.name,
  }));

  const handleColumnChange = (selectedOptions) => {
    setSelectedColumns(selectedOptions);
    setGlobalColumns(selectedOptions.map((option) => option.value));
  };

  const toggleSelection = (mediaType) => {
    setMediaSelection((prevState) => ({
      ...prevState,
      [mediaType]: !prevState[mediaType],
    }));
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearchDateChange = (e) => {
    setSearchDate(e.target.value);
  };

  const handleSearchPress = () => {
    setSearchState(searchInput);
    setGlobalDate(searchDate);
  };

  const handleDownloadCSV = async () => {
    try {
      setCSVLoading(true);
      if (From === "" || To === "") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please fill all fields",
        });
        return;
      }

      const from = new Date(From);
      const to = new Date(To);
      if (from >= to) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "From date should be before To date",
        });
        return;
      }

      const response = await postAuth("/post/download-csv", {
        from: from.toISOString(),
        to: to.toISOString(),
        searchString: searchInput,
        Language: selectedLanguage.value,
      });

      if (response.ok) {
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = downloadUrl;

        const filename = searchInput
          ? `${sanitizeFilename(searchInput)}_posts.csv`
          : "filtered_posts.csv";
        a.download = filename;

        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(downloadUrl);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "File has been downloaded successfully.",
        });
      } else {
        const errorData = await response.json();
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorData.message || "Failed to download file.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Unexpected Error",
        text: "An unexpected error occurred. Please try again.",
      });
      console.error("Error during handleSubmit:", error);
    } finally {
      setCSVLoading(false);
    }
  };

  const handleDownload = async () => {
    try {
      setMediaLoading(true);

      if (From === "" || To === "") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please fill all fields",
        });
        return;
      }

      const from = new Date(From);
      const to = new Date(To);
      if (from >= to) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "From date should be before To date",
        });
        return;
      }

      if (!mediaSelection.images && !mediaSelection.videos) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please select at least one type of media",
        });
        return;
      }

      // Send to backend
      const response = await postAuth("/post/download-column-media", {
        searchString: searchInput,
        mediaSelection,
        from: from.toISOString(),
        to: to.toISOString(),
      });

      if (response.ok) {
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");

        a.href = downloadUrl;
        a.download = "media.zip";

        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(downloadUrl);

        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Media has been downloaded successfully.",
        });
      } else {
        const errorData = await response.json();
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorData.message || "Failed to download media.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Unexpected Error",
        text: "An unexpected error occurred.",
      });
      console.error("Error during handleDownload:", error);
    } finally {
      setMediaLoading(false);
    }
  };

  return (
    <BookmarkList $show={show}>
      <div className="search-top">
        <p className="search-instructions">
          Search All Columns<span className="material-icons">search</span>
        </p>

        <div className="input-icon-container">
          <ThemedInput
            type="text"
            className="ColumnNameInput TextArea"
            placeholder="Search..."
            value={searchInput}
            onChange={handleSearch}
            title="Press Enter to search"
          />
          <span className="material-icons search-icon">search</span>
        </div>

        <StyledSelect
          isMulti
          options={columnOptions}
          value={selectedColumns}
          onChange={handleColumnChange}
          placeholder="Select Specific Columns (optional)"
          theme={theme}
          styles={{
            control: (base, state) => ({
              ...base,
              fontSize: "14px",
              backgroundColor: theme === "dark" ? "#333" : "#fff",
            }),
            menu: (base) => ({
              ...base,
              backgroundColor: theme === "dark" ? "#333" : "#fff",
            }),
            singleValue: (base) => ({
              ...base,
              color: theme === "dark" ? "#fff" : "#000",
            }),
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isFocused
                ? theme === "dark"
                  ? "#555"
                  : "#ddd"
                : theme === "dark"
                ? "#333"
                : "#fff",
              color: state.isFocused
                ? theme === "dark"
                  ? "#fff"
                  : "#000"
                : theme === "dark"
                ? "#fff"
                : "#000",
              cursor: "pointer",
            }),
            input: (base) => ({
              ...base,
              color: theme.text,
            }),
          }}
        />

        <DatePicker
          type="datetime-local"
          className="date-time-picker"
          $theme={theme}
          value={searchDate}
          onChange={handleSearchDateChange}
          title="Filter By Date"
        />

        <SearchButton onClick={handleSearchPress}>Search</SearchButton>

        {/* Shared Date Range Inputs */}
        <SectionContainer>
          <SectionTitle>Media Download Options</SectionTitle>

          <p>Set Date Interval</p>
          <InputContainer>
            <label>From</label>
            <DatePicker
              className="date-time-picker"
              $theme={theme}
              type="datetime-local"
              value={From}
              onChange={handleFromChange}
              style={{ marginTop: "10px", marginBottom: "10px" }}
            />
          </InputContainer>
          <InputContainer>
            <label>To</label>
            <DatePicker
              className="date-time-picker"
              $theme={theme}
              type="datetime-local"
              value={To}
              onChange={handleToChange}
              style={{ marginTop: "10px" }}
            />
          </InputContainer>
          <p>Choose Text Language</p>
          <InputContainer>
            <StyledSelect
              options={[
                { value: "Original", label: "Original" },
                { value: "en", label: "English" },
                { value: "ar", label: "Arabic" },
                { value: "es", label: "Spanish" },
                { value: "fr", label: "French" },
                { value: "de", label: "German" },
                { value: "ru", label: "Russian" },
              ]}
              value={selectedLanguage}
              onChange={handleLanguageChange}
              styles={{
                control: (base, state) => ({
                  ...base,
                  fontSize: "14px",
                  backgroundColor: theme === "dark" ? "#333" : "#fff",
                }),
                menu: (base) => ({
                  ...base,
                  backgroundColor: theme === "dark" ? "#333" : "#fff",
                }),
                singleValue: (base) => ({
                  ...base,
                  color: theme === "dark" ? "#fff" : "#000",
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isFocused
                    ? theme === "dark"
                      ? "#555"
                      : "#ddd"
                    : theme === "dark"
                    ? "#333"
                    : "#fff",
                  color: state.isFocused ? theme.text : theme.text,
                  cursor: "pointer",
                }),
                input: (base) => ({
                  ...base,
                  color: theme.text,
                }),
              }}
            />
          </InputContainer>
        </SectionContainer>

        {CSVloading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </div>
        ) : (
          <SubmitButton
            onClick={handleDownloadCSV}
            theme={theme}
            disabled={CSVloading}
          >
            Download CSV
          </SubmitButton>
        )}

        {/* Media Download Section */}
        <SectionContainer>
          <RadioContainer
            style={{ justifyContent: "center", marginBottom: "10px" }}
          >
            <CheckBoxInput>
              <input
                type="checkbox"
                id="videos"
                name="media"
                checked={mediaSelection.videos}
                onChange={() => toggleSelection("videos")}
              />
              <label htmlFor="videos">Videos</label>
            </CheckBoxInput>
            <CheckBoxInput>
              <input
                type="checkbox"
                id="images"
                name="media"
                checked={mediaSelection.images}
                onChange={() => toggleSelection("images")}
              />
              <label htmlFor="images">Images</label>
            </CheckBoxInput>
          </RadioContainer>
          {MediaLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </div>
          ) : (
            <SubmitButton
              theme={theme}
              onClick={handleDownload}
              disabled={MediaLoading}
            >
              Download Media
            </SubmitButton>
          )}
        </SectionContainer>
      </div>
    </BookmarkList>
  );
};

export default SearchComponent;
